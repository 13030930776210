import anime from 'animejs/lib/anime';

$('.navigation a[data-bs-toggle="collapse"]').click(function() {
  $('.collapse.show').collapse('hide')
});

$('#collapseBrands a[data-bs-toggle="collapse"]').click(function() {
  $('#collapseBrandsContent .collapse.show').collapse('hide')
});

$('.navigation a[data-bs-toggle="collapse"]').click(function() {
  if ($('.navigation a[aria-expanded="false"]').length === 3) {
    $('.lead').collapse('show');
  }
  else if ($('.navigation a[aria-expanded="false"]').length != 3) {
    $('.lead').collapse('hide');
  }
});
// Create a timeline with default parameters
var tl = anime.timeline({
  easing: 'easeInOutQuad',
});

// Add children
tl
.add({
  delay: 150,
  targets: '.logoS',
  translateX: 0,
  duration: 400
})
.add({
  targets: '.logoT',
  opacity: '1',
  translateX: 0,
  duration: 400
})
.add({
  targets: '.logoA',
  opacity: '1',
  translateX: 0,
  duration: 400
})
.add({
  targets: '.logoTotal',
  delay: 400,
  fill: '#000',
  opacity: 1,
})
.add({
  targets: 'body',
  backgroundColor: '#d9dbd7',
  duration: 550
})
.add({
  targets: 'main',
  opacity: [0,1]
})
;

